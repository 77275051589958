import { ApiService } from './api.service';
import { Utils } from './utils';
declare global {
    interface Window {
        ApplePaySession: any;
    }
}

interface TxInfo {
    amount: number;
    currency: string;
    massEnrollmentMerchantId: string;
    merchantDisplayName: string;
    merchantSiteDomain: string;
    serviceURL: string;
}
export default class CgApplePay {
    protected ApplePaySession = window.ApplePaySession;
    private currentSession: any;
    private txInfo: TxInfo;
    private utils: Utils = null;
    private postMessagePayload: any = {
        dataType: 'applepay',
        action: 'Alive'
    }
    constructor(private debugState: boolean = false) {
        this.utils = new Utils(this.debugState);
        window.addEventListener("message",
        (e) => {
            console.log('parent window listen', e)
            const action = e.data.action;
            // if (e.origin.split('.').splice(1,3).join('.') !== 'creditguard.co.il' && e.data.type !== 'applepay') { return; }
            if(action == 'isAlive'){
                this.returnToIframe(e, this.postMessagePayload)
            }
            if(action == 'startDeal'){
                const txInfo = e.data.txInfo;
                this.init( txInfo, (token: any) => {
                    this.postMessagePayload.action = 'submitDeal'
                    this.postMessagePayload.token = token;
                    this.returnToIframe(e, this.postMessagePayload)
                } );
            }
            if(action == 'paymentSucceeded'){
                this.sucStatus();
            }
            if(action == 'paymentFailure'){
                this.failStatus()
            }
        }, false);
    }
    public returnToIframe = (e: any, appleData: any) => e.source.postMessage(appleData, e.origin);

    public get session(): string {
        return this.currentSession;
    }

    public sucStatus(): string {
        return this.currentSession.completePayment(this.ApplePaySession.STATUS_SUCCESS);
    }
    public failStatus(): string {
        return this.currentSession.completePayment(this.ApplePaySession.STATUS_FAILURE);
    }


    public async init(txInfo: any, cb?: Function) {
        if (!this.ApplePaySession) {
            console.error("this browser does not support apple pay");
        } else {
            let canMakePayments = this.ApplePaySession.canMakePayments();
            this.txInfo = txInfo;
            if (canMakePayments) {
                this.startPayEvent(cb);
            } else {
                console.error("fail to generate apple pay process");
            }
        }
    }

    public async performValidation(): Promise<any> {
        try {
            const apiService = new ApiService();
            var raw = JSON.stringify({
                "action": "performValidation",
                "displayName": this.txInfo.merchantDisplayName,
                "mid": this.txInfo.massEnrollmentMerchantId,
                "domainName": this.txInfo.merchantSiteDomain
            });
            return await apiService.post(this.txInfo.serviceURL, raw);
        } catch (e) {
            this.utils.logit(e);
            return "fail to call verify merchant";
        }
    }
    public startPayEvent(cb: Function) {

        let runningAmount: any = this.txInfo.amount;
        let runningPP: any = 0;
        let runningTotal = function () {
            let tempTotal: any = parseFloat(runningAmount) + parseFloat(runningPP);
            return parseFloat(tempTotal).toFixed(2);
        };


        let subTotalDescr = this.txInfo.merchantDisplayName;

        let paymentRequest = {
            currencyCode: this.txInfo.currency,
            countryCode: "IL",
            requiredBillingContactFields: ["email", "name", "phone"],
            lineItems: [{ label: subTotalDescr, amount: runningAmount }],
            total: {
                label: this.txInfo.merchantDisplayName,
                amount: runningTotal()
            },
            supportedNetworks: ["amex", "masterCard", "visa"],
            merchantCapabilities: ["supports3DS"]
        };

        let session = new this.ApplePaySession(1, paymentRequest);
        this.currentSession = session;
        // Merchant Validation
        session.onvalidatemerchant = async (event: any): Promise<any> => {
            this.utils.logit(event);
            try {
                this.utils.logit('start verify merchant');
                // TODO - export performValidation to pps use only
                let merchantSession = await this.performValidation();
                const data = JSON.parse(await merchantSession.text());
                this.utils.logit('finish verify')

                session.completeMerchantValidation(data);
            } catch (e) {
                this.utils.logit(e);
                return "fail to verify merchant";
            }
        };




        session.onpaymentmethodselected = (event: any) => {
            this.utils.logit("starting session.onpaymentmethodselected");
            this.utils.logit(event);

            let newTotal = {
                type: "final",
                label:  this.txInfo.merchantDisplayName,
                amount: runningTotal()
            };
            let newLineItems = [
                { type: "final", label: subTotalDescr, amount: runningAmount }
            ];
            session.completePaymentMethodSelection(newTotal, newLineItems);
        };
        /**
         @param status - reffer to the apple pay session status
         @param validToken - reffer to the transaction details
         */
        session.onpaymentauthorized = async (event: any) => {
            this.utils.logit("starting session.onpaymentauthorized");
            this.utils.logit('NB: This is the first stage when you get the *full shipping address* of the customer, in the event.payment.shippingContact object');
            this.utils.logit(event);
            this.utils.logit(event.payment.shippingContact);
            let validToken = event.payment.token;
            try {
                // validToken = await sendPaymentToken(event.payment.token);
                cb(validToken)
            } catch (error) {
                console.error(error)
                cb(error)
            }
        };


        session.oncancel = (event: any) => {
            this.utils.logit("starting session.cancel");
            this.utils.logit(event);
        };

        session.begin();

        return session;
    }

}

module.exports = CgApplePay;
